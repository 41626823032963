import React from 'react'

import ArticleReference from '../articleReference/articleReference.component'
import './newsColumn.styles.scss'


export default function NewsColumn(props) {
    if (props.enabled && props.content.length > 0) {
        return <div className="newsColumn" >
            <div className="newsTitle">{props.title}</div>
            <div className="newsColletion">
                {props.content.map(({ id, link, imageUrl, text }) => (
                    <ArticleReference key={id} link={link} imageUrl={imageUrl} teaserText={text} />
                ))
                }
            </div>
        </div>
    }
    else {
        return <div></div>
    }
}


