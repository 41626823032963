import React from 'react'

import './articleReference.styles.scss'
import { NavHashLink } from 'react-router-hash-link';


export default function ArticleReference(props) {
    if (props.link) {
    return (
        <NavHashLink to={props.link}>
        <div className="articleReference">
            <div className="articleImage" style={{ backgroundImage: `url(${props.imageUrl})` }}></div>
            <div className="articleContent">
                <div className="articleText">{props.teaserText}</div>
            </div>
        </div>
        </NavHashLink>
    )
    }
    else{
        return (
            <div className="articleReference">
                <div className="articleImage" style={{ backgroundImage: `url(${props.imageUrl})` }}></div>
                <div className="articleContent">
                    <div className="articleText">{props.teaserText}</div>
                </div>
            </div>
        )
    }
}
