import React from 'react'

import './comments.styles.scss'
import Slider from 'infinite-react-carousel';


class Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            enabled: this.props.content.enabled,
        }
    }

    render() {
        if (this.state.enabled === false)
        {
            return <div></div>
        }

        const settings = {
            adaptiveHeight: false,
            centerMode: false,
            slidesPerRow: 1,
            autoplay: true,
            arrows: false,
            dots:true,
            autoplaySpeed: 5000,
        };
        return (
            <div className="comments" >
                <span className="goto" id="comments"></span>
                <div className="content">
                    <div className="left">
                        <div className="padding">
                            <h2 className="bold leftTitle">{this.props.content.title}</h2>
                            <h2 className="leftTitle">{this.props.content.subTitle}</h2>
                        </div>
                    </div>
                    <div className="right">
                        <Slider {...settings} className="commentsCarousel">
                            {
                                this.props.content.comments.map(({ id, link, linkTitle }) => ( 
                                    <div  key={id}>
                                    <div className="commentInCarousel">
                                        <div className="comment">
                                            <div className="text">"{linkTitle}"</div>
                                            <div className="author">{link}</div>
                                        </div>
                                    </div>
                                    </div>
                                ))
                            }
                           </Slider>
                           <div className="banner">
                                <img src={this.props.content.imageUrl} srcSet={this.props.content.imageSrcSet} sizes={this.props.content.imageSizes} alt=""/>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Comments