import React from 'react'
import '../../page.styles.scss'
import './newsallpage.styles.scss'


import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import NewsItem from '../../components/news/newsItem.component'

export class NewsAllPage extends React.Component {
    constructor(props) {
        super(props);

        var savedCulture = localStorage.getItem("siteLanguage");

        if (savedCulture === undefined) {
            savedCulture = "pt-pt";
        }

        this.state =
        {
            isLoaded: false,
            error: false,
            currentLanguage: savedCulture,
            content: { key: "none", value: {} }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.UpdateContent(this.props.language)
        }
    }

    UpdateContent(culture) {
        var content = this.state.content[culture]
        if (content) {
            this.setState({
                currentLanguage: culture
            });
            return;
        }

        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // request successful
                    var response = xhr.responseText;
                    var json = JSON.parse(response);

                    let copyContent = { ...this.state.content }; //create a new copy

                    copyContent[culture] = json;

                    this.setState({
                        isLoaded: true,
                        error: false,
                        content: copyContent,
                        currentLanguage: culture
                    });

                } else {
                    // error
                    this.setState({
                        isLoaded: true,
                        error: true
                    });
                }
            }
        });

        xhr.open("GET", process.env.REACT_APP_APIURL  + "/content/news?culture=" + culture, true);
        xhr.send();
    }

    componentDidMount() {
        this.UpdateContent(this.state.currentLanguage);
    }

    render() {
        var content = this.state.content[this.state.currentLanguage];
        
        if (content) {
            return (<div className="page" >
                    <div className="allNews" >
                        <Breadcrumbs crumbs={this.props.crumbs} />
                        <div className="content">
                            <div className="right">
                                <div className="allServicesTitle">{content.title}</div>
                                <div className="container">
                                    {content.newsItems.map(({ title, id, link, imageUrl, text, readMore }) => (
                                    <NewsItem key={id} title={title} link={link} imageUrl={imageUrl} text={text} readMore= {readMore}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div className="page" />);
        }
    }
}
