import React from 'react'

import '../../page.styles.scss'

import Hero from '../../components/hero/hero.component'
import Services from '../../components/services/services.component'
import Contacts from '../../components/contacts/contacts.component'
import Adverts from '../../components/adverts/adverts.component'
import Comments from '../../components/comments/comments.component'
import Links from '../../components/links/links.component'
import News from '../../components/news/news.component'

export default function HomePage({payload}) {
  var content = payload.homepage;

  return (
    <div className="page" id="homepage">
      <Hero content={content.hero} />
      <Services content={content.services} />
      <Links content={content.links} />
      <News content={content.news} />
      <Contacts content={content.contacts} />
      <Adverts content={content.adverts} />
      <Comments content={content.comments} />
    </div>
  );
}