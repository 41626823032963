import React from 'react'
import '../../page.styles.scss'

import './alladverts.styles.scss'
import './advert.styles.scss'

import parse from 'html-react-parser';
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import Related from '../../components/related/related.component'

import { CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import AdvertItem from '../../components/adverts/AdvertItem.component'


// export function AdvertAllPage() {

//     return (
//         <div className="page" >
//             <div className="advertsPage">
//                 <div className="content">
//                     <h2>Adverts page</h2>
//                 </div>
//             </div>
//         </div>
//     );
// }

// import React from 'react'
// import '../../page.styles.scss'
// import './newsallpage.styles.scss'


// import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
// import NewsItem from '../../components/news/newsItem.component'

export class AdvertAllPage extends React.Component {
    constructor(props) {
        super(props);

        var savedCulture = localStorage.getItem("siteLanguage");

        if (savedCulture === undefined) {
            savedCulture = "pt-pt";
        }

        this.state =
        {
            isLoaded: false,
            error: false,
            currentLanguage: savedCulture,
            content: { key: "none", value: {} }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.UpdateContent(this.props.language)
        }
    }

    UpdateContent(culture) {
        var content = this.state.content[culture]
        if (content) {
            this.setState({
                currentLanguage: culture
            });
            return;
        }

        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // request successful
                    var response = xhr.responseText;
                    var json = JSON.parse(response);

                    let copyContent = { ...this.state.content }; //create a new copy

                    copyContent[culture] = json;

                    this.setState({
                        isLoaded: true,
                        error: false,
                        content: copyContent,
                        currentLanguage: culture
                    });

                } else {
                    // error
                    this.setState({
                        isLoaded: true,
                        error: true
                    });
                }
            }
        });

        xhr.open("GET", process.env.REACT_APP_APIURL  + "/content/adverts?culture=" + culture, true);
        xhr.send();
    }

    componentDidMount() {
        this.UpdateContent(this.state.currentLanguage);
    }

    render() {
        var content = this.state.content[this.state.currentLanguage];
        
        if (content) {
            return (<div className="page" >
                    <div className="allAdverts" >
                        <Breadcrumbs crumbs={this.props.crumbs} />
                        <div className="content">
                            <div className="right">
                                <div className="allAdvertsTitle">{content.title}</div>
                                <div className="container">
                                    {content.newsItems.map(({ title, id, link, imageUrl, text , readMore}) => (
                                    <AdvertItem key={id} title={title} link={link} imageUrl={imageUrl} text={text} readMore= {readMore} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div className="page" />);
        }
    }
}


export class Advert extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            pathId: props.pathId,
            numberOfSlides: this.getDimension(),
            isLoaded: false,
            error: false,
            currentLanguage: props.language,
            content: { key: "none", value: {} }
        };
    }

    updateDimensions = () => {
        this.setState({ numberOfSlides: this.getDimension() });
    };

    getDimension() {
        var nSlides = 3;

        if (window.innerWidth > 645 && window.innerWidth < 1080) {
            nSlides = 2;
        }

        if (window.innerWidth < 645) {
            nSlides = 1;
        }
        return nSlides;
    }

    componentDidMount() {
        this.UpdateContent();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if(this.props.pathId !== prevProps.pathId || this.props.language !== prevProps.language)
        {
            this.UpdateContent()
        }
    }

    UpdateContent() {
        var serviceKey = this.getServiceKey(this.props.language);
        var language =  this.props.language;
        var serviceId = this.props.pathId;
        
        var content = this.state.content[serviceKey];

        if (content) {
            this.setState( {
                currentLanguage: this.props.language
            });

            return;
        }

        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // request successful
                    var response = xhr.responseText;
                    var json = JSON.parse(response);

                    let copyContent = { ...this.state.content }; //create a new copy

                    copyContent[serviceKey] = json;

                    this.setState({
                        isLoaded: true,
                        error: false,
                        content: copyContent,
                        currentLanguage: language
                    });

                } else {
                    // error
                    this.setState({
                        isLoaded: true,
                        error: true
                    });
                }
            }
        });

        xhr.open("GET", process.env.REACT_APP_APIURL  + "/content/adverts/" + serviceId + "?culture=" + language, true);
        xhr.send();
    }

    getServiceKey(language)
    {
        return this.props.match.params.id + language;
    }

    render() {
        var serviceKey = this.getServiceKey(this.state.currentLanguage);
        var content = this.state.content[serviceKey];
        var body;

        if(content === undefined)
        {
            return  <div>Loading...</div>;
        }

        if (!this.state.isLoaded) {
            // yet loading
            body = <div>Loading...</div>
        } else if (this.state.error) {
            // error
            body = <div>Error occured</div>
        } else {
            this.props.crumbs[this.props.crumbs.length - 1].name = content.title;

            body = <div className="page" >
                <div className="service" id={this.props.pathId}>
                    <Breadcrumbs crumbs={this.props.crumbs} />
                    <div className="content">
                        <div>
                            {parse(content.text)}
                        </div>
                        <div>
                            <CarouselProvider className="serviceImages"
                            naturalSlideWidth={100}
                            naturalSlideHeight={200}
                            visibleSlides={this.state.numberOfSlides}
                                infinite={true}
                                isPlaying={true}
                                interval={6000}
                                totalSlides={content.images.length}>
                                <Slider>
                                    {
                                        content.images.map(({ id, imageUrl, alt }) => (
                                            <Slide key={id}><img className="img" src={imageUrl} alt={alt} /></Slide>
                                        ))
                                    }
                                </Slider>
                            </CarouselProvider>
                        </div>
                        
                    </div>
                </div>
            </div>
        }

        return body;
    }
}
