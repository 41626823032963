import React from 'react'
import  { Redirect } from 'react-router-dom'
import './Reset.css';
import './App.css';
import './rte.styles.scss'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'

import HomePage from './pages/homepage/homepage.component'
import { ServicesPage, Service } from './pages/services/servicespage.component'
import BookingPage from './pages/booking/bookingpage.component'
import { AdvertAllPage, Advert } from './pages/adverts/advertspage.component'

import { NewsAllPage } from './pages/news/newsallpage.component'
import Header from './components/header/header.component'
import Footer from './components/footer/footer.component'
import CookieConsent from "react-cookie-consent";
import NewsContentPage from './pages/newsContent/newscontentpage.component'

const KeysToComponentMap = {
  homePage: HomePage,
  servicesPage: ServicesPage,
  service: Service,
  bookingPage: BookingPage,
  advertAllPage: AdvertAllPage,
  advert : Advert,
  bookinAdvertgPage: Advert,
  newsContent : NewsContentPage,
  newsAllPage : NewsAllPage
};



class App extends React.Component {
  constructor() {
    super();

    var savedCulture = localStorage.getItem("siteLanguage");

    if(savedCulture === undefined )
    {
      savedCulture =  "pt-pt";
    }

    this.state =
    {
      isLoaded: false,
      error: false,
      currentLanguage: savedCulture,
      content: { key: "none", value: {} }
    }
    
    this.changeCulture = this.changeCulture.bind(this)
  }

  changeCulture(newCulture) {
    localStorage.setItem("siteLanguage", newCulture);
    this.UpdateContent(newCulture)
  }

  UpdateContent(culture) {
    var content = this.state.content[culture]
    if(content)
    {
      this.setState({
        currentLanguage: culture
      });

      return;
    }

    var xhr = new XMLHttpRequest();

    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // request successful
          var response = xhr.responseText;
          var json = JSON.parse(response);

          let copyContent = { ...this.state.content }; //create a new copy
          copyContent[culture] = json;

          this.setState({
            isLoaded: true,
            error: false,
            content: copyContent,
            currentLanguage: culture
          });

          document.title = json.title;

        } else {
          // error
          this.setState({
            isLoaded: true,
            error: true
          });
        }
      }
    });

    xhr.open("GET", process.env.REACT_APP_APIURL + "/content/app?culture=" + culture, true);
    xhr.send();
  }

  componentDidMount() {
    this.UpdateContent(this.state.currentLanguage);
  }

  render() {
    var body;
    if (!this.state.isLoaded) {
      body = <div className="initialLoader"><img src="/favicon.svg"></img><div className="loader"></div></div>;
    } else if (this.state.error) {
      body = <div className="initialLoader"><img src="/favicon.svg"></img><div className="loader"></div></div>;
    } else {
      body = <Router>
        <CookieConsent
          location="bottom"
          buttonText={this.state.content[this.state.currentLanguage].cookie.button}
          containerClasses="cookieConsent"
          buttonClasses="cookieConsent-btn"
          style={{
            background: "var(--color-darkGray)",
          }}
          buttonStyle={{
            background: "var(--color-red)",
            color: "white"
          }}
        >{this.state.content[this.state.currentLanguage].cookie.text}
        </CookieConsent>
        <ScrollToTop>
          <div className="app">
            <Header content={this.state.content[this.state.currentLanguage].menu} active={1} changeCultureHandler={this.changeCulture} />
            <Switch>
              {this.state.content[this.state.currentLanguage].routes.map(({ path, exact, name, component }, key) => (
                <Route exact={exact} path={path} key={window.location.pathname}
                  render={props => {
                    const payload = this.state.content[this.state.currentLanguage].payload;
                    const crumbs = this.state.content[this.state.currentLanguage].routes
                      .filter(({ path }) => props.match.path.includes(path))
                      .map(({ path, ...rest }) => ({
                        path: Object.keys(props.match.params).length
                          ? Object.keys(props.match.params).reduce(
                            (path, param) =>
                              path.replace(`:${param}`, props.match.params[param]),
                            path
                          )
                          : path,
                        ...rest
                      }));

                    const DynamicComponent = KeysToComponentMap[component];
                    return (
                      <div>
                        <DynamicComponent {...props} title={name} crumbs={crumbs} payload={payload} language={this.state.currentLanguage} pathId={props.match.params.id} key={window.location.pathname} />
                      </div>
                    );
                  }}
                />
               
              ))}
                <Redirect to="/" />
            </Switch>
            <Footer content={this.state.content[this.state.currentLanguage].footer} />
          </div>
        </ScrollToTop>
      </Router >
    }

    return body;
  }
}

export default App;
