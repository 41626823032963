import React from 'react'

import './contacts.styles.scss'

class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            enabled: this.props.content.enabled,
        }
    }

    render() {
        if (this.state.enabled === false)
        {
            return <div></div>
        }
        
        return (
            <div className="contacts" style={{ backgroundImage: `url(${this.props.content.background})` }}>
                <span className="goto" id="contacts"></span>
                <div className="content">
                    <div className="left">
                        <div className="padding">
                            <h2 className="bold leftTitle">{this.props.content.title}</h2>
                            <h2 className="leftTitle">{this.props.content.subTitle}</h2>
                        </div>
                    </div>
                    <div className="right">
                        <div className="padding">
                            <div className="texts">
                                <div className="address">
                                    <div className="title titleHided">
                                        <h2><span className="bold">{this.props.content.address.title}</span>&nbsp;{this.props.content.address.subTitle}</h2>
                                    </div>
                                    <div className="addressValue">
                                        {this.props.content.address.street}<br />{this.props.content.address.postalCode}
                                    </div>
                                    <div className="phones">
                                        {
                                            this.props.content.address.phones && this.props.content.address.phones.map(({ id, title, link, linkTitle }) => (
                                                <div className="phone" key={id}>
                                                    <span className="title bold">{title}</span>&nbsp;<a className="link" href={link}>{linkTitle}</a>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="schedules">
                                    <div className="title titleHided">
                                        <h2><span className="bold">{this.props.content.schedules.title}</span> {this.props.content.schedules.subTitle}</h2>
                                    </div>
                                    <div className="headings">
                                        <h2 className="bold">{this.props.content.schedules.heading1}</h2>
                                        <h2 className="bold">{this.props.content.schedules.heading2}</h2>
                                    </div>
                                    <div className="serviceSchedules">
                                        {
                                            this.props.content.schedules.services && this.props.content.schedules.services.map(({ id, title, linkTitle }) => (
                                                <div className="service" key={id}>
                                                    <h3 className="title">{title}</h3>
                                                    <h3 className="schedule">{linkTitle}</h3>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="map">
                            <iframe title={this.props.content.map.title} src={this.props.content.map.link}  frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                </div>
            </div>
        );
    }
}


export default Contacts;