import React from 'react'
import '../../page.styles.scss'
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs.component";
import './booking.styles.scss'


function BookingPage(props) {

    return (
        <div className="page" >
            <div className="allServices">
            <Breadcrumbs crumbs={props.crumbs}/>
                <div className="content">
                    <h2>Booking page</h2>
                </div>
            </div>
        </div>
    );
}

export default BookingPage;