import React from 'react'

import './adverts.styles.scss'

import AdvertsItem from './advertsItem.component'
import { NavHashLink } from 'react-router-hash-link';

const Adverts = ({ content }) => {
    if (content.enabled === true && content.adverts && content.adverts.length > 0) {
        return <div className="adverts" >
            <span className="goto" id="adverts"></span>
            <div className="content">
                <div className="left">
                    <div className="padding">
                        <h2 className="bold leftTitle">{content.title}</h2>
                        <h2 className="leftTitle">{content.subTitle}</h2>
                    </div>
                    <div className="alladverts padding">
                        <NavHashLink to={content.alladvertsLink}><h3 className="leftTitle">{content.alladverts}</h3></NavHashLink>

                        <div className="arrowContainer">
                            <span className="arrow" style={{ backgroundImage: `url(${content.alladvertsImageUrl})` }}></span>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="container">
                        {
                            content.adverts && content.adverts.map(({ id, title, imageUrl, link }) => (
                                <AdvertsItem key={id} id={id} title={title} link={link} imageUrl={imageUrl} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    }
    else{
        return <div></div>
    }
};

export default Adverts