import React from 'react'
import { NavHashLink } from 'react-router-hash-link';

import './news.styles.scss'
import ArticleReference from '../articleReference/articleReference.component'


export default function News(props) {
    if (props.content.enabled === true && props.content.newsColletion && props.content.newsColletion.length > 0) {
        return <div className="News" >
            <div className="content">
                <div className="left">
                <div className="padding newsTitle">
                        <h2 className="bold leftTitle">{props.content.title}<span>&nbsp;</span></h2>
                        <h2 className="leftTitle">{props.content.subTitle}</h2>
                    </div>
                    <div className="padding allNews">
                            <NavHashLink to={props.content.allNewsLink}><h3 className="leftTitle">{props.content.allNews}</h3></NavHashLink>
                    </div>
                </div>
                <div className="right">
                <div className="newsColletion">
                    {props.content.newsColletion.map(({ title, id, link, imageUrl, text }) => (
                        <ArticleReference key={id} title={title} link={link} imageUrl={imageUrl} teaserText={text} />
                    ))
                    }
                </div>
                </div>

            </div>
        </div>
    }
    else {
        return <div></div>
    }
}
