import React from 'react'

import './hero.styles.scss'
import { NavHashLink } from 'react-router-hash-link';
import parse from 'html-react-parser';


class Hero extends React.Component {
    constructor(props) {
        super(props);

        if (props.content.enabled) {
            this.state =
            {
                enabled: true,
                currentImg: Math.floor(Math.random() * (this.props.content.images.length))
            }
        }
        else {
            this.state =
            {
                enabled: false
            }
        }
    }

    componentDidMount() {
        if (this.state.enabled) {
            this.interval = setInterval(() => this.changeBackgroundImage(), this.props.content.imagesChangesTime);
        }
    }

    componentWillUnmount() {
        if (this.state.enabled) {
            if (this.interval) {
                clearInterval(this.interval);
            }
        }
    }

    changeBackgroundImage() {
        let newCurrentImg = 0;
        const currentImg = this.state.currentImg;
        const content = this.props.content;
        const noOfImages = content.images.length;

        if (currentImg !== noOfImages - 1) {
            newCurrentImg = currentImg + 1;
        }

        this.setState({ currentImg: newCurrentImg });
    }

    render() {
        if (this.state.enabled === false) {
            return <div></div>
        }

        const currentImg = this.state.currentImg;
        const content = this.props.content;

        return (
            <div className="hero" >
                <div className="image" style={content.images.length > 0 ? { backgroundImage: `url('${content.images[currentImg].link}')` } : null}></div>
                <div className="content">
                    <div className="left">
                        <h2>{content.titleLine1}</h2>
                        <h2>{content.titleLine2}</h2>
                        { content.location.link === undefined ? null : <NavHashLink to={content.location.link}> <h3>{content.location.title}</h3></NavHashLink> }
                        <div className="heroText">
                            <div>{parse(content.textLine1)}<br />{parse(content.textLine2)}</div>
                        </div>
                        { content.enableLink === false || content.link.link === undefined ? null : <NavHashLink className="button" to={content.link.link}>{content.link.title}</NavHashLink> }
                    </div>
                    <div className="right"></div>
                </div>
            </div>
        );
    }
}

export default Hero;
