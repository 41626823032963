import React from 'react'

import './footer.styles.scss'

const Footer = ({ content }) =>
(
    <div className="footer" >
        <div className="content">
            <div className="left">
                <div className="copyright"></div>
            </div>
            <div className="right">
                <div className="shortcuts">
                    <div className="followup">
                        <div className="title">{content.linksTitle}</div>
                        <div className="icons">
                            {
                                content.links.map(({ id, imageUrl, alt, link }) => (
                                    <div key={id}><a href={link} target="_blank" rel="noreferrer"><img src={imageUrl} alt={alt} /></a></div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="shortcutsLinks">
                        <div className="shortcutsLinksBottom">
                        {
                            content.shortcuts.map(({ id, link, text }) => (
                                <a  key={id} href={link} target="_blank" rel="noreferrer">{text}</a>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <div className="copyright">{content.copyright}</div>
            </div>
        </div>
    </div>
);

export default Footer;