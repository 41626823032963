import React from 'react'

import './lineHeader.styles.scss'

class LineHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            menu: this.props.menu
        }
    }



    render() {
        var changeCultureHandler = this.props.changeCultureHandler;

        return (
            <div className="lineheader">
                <div className="menu wrap">
                    {
                        <ul>
                            {
                                this.state.menu.map(({ title, id, imageUrl, link }) => (
                                    <li key={id}><a href={link}><span className="icon" style={{ backgroundImage: `url(${imageUrl})` }}></span>{title}</a></li>
                                ))
                            }
                            <li className="languageItem" key="pt" onClick={() => changeCultureHandler('pt-pt')}>PT</li>
                            <li className="languageItem" key="en" onClick={() => changeCultureHandler('en')}>EN</li>
                        </ul>
                    }
                </div>
            </div>
        )
    }
}
export default LineHeader;