import React from 'react'
import parse from 'html-react-parser';
import './newsItem.styles.scss'
import { NavHashLink } from 'react-router-hash-link';


class NewsItem extends React.Component {
    render() {
        return <InnerNewsItem imageUrl={this.props.imageUrl} title={this.props.title} text={this.props.text} link={this.props.link} readMore={this.props.readMore}></InnerNewsItem>
    }
}


function InnerNewsItem(props) {
    if (props.link) {
        return <div className="newsItem">
            <div className="content">
            <NavHashLink to={props.link} className="readmore">
                <div className="imageContainer">
                    <img className="image" src={props.imageUrl} alt={props.title} />
                    <div className="newsTitle">
                        <h2 className="title bold">{props.title}</h2>
                    </div>
                </div>
                </NavHashLink>
                <div className="newsItemText">
                    <div className="newsItemTextContent">{parse(props.text)}</div>
                    <NavHashLink to={props.link} className="readmore">{props.readMore}</NavHashLink>
                </div>
            </div>
        </div>
    }
    else {
       return  <div className="newsItem">
       <div className="content">
           <div className="imageContainer">
               <img className="image" src={props.imageUrl} alt={props.title} />
               <div className="newsTitle">
                   <h2 className="title bold">{props.title}</h2>
               </div>
           </div>
           <div className="newsItemText">
               <div className="newsItemTextContent">{parse(props.text)}</div>
           </div>
       </div>
   </div>
    }
}



export default NewsItem;