import React from 'react'

import './services.styles.scss'
import ServicesItem from './servicesItem.component'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { NavHashLink } from 'react-router-hash-link';

class Services extends React.Component {
    constructor(props) {
        super(props);
        if (props.content.enabled) {
            this.state =
            {
                enabled: true,
                numberOfSlides: this.getDimension(),
                content: { key: "none", value: {} }
            }
        }
        else {
            this.state =
            {
                enabled: false
            }
        }
    }

    updateDimensions = () => {
        this.setState({ numberOfSlides: this.getDimension() });
    };

    getDimension() {
        var nSlides = 4;

        if (window.innerWidth > 563 && window.innerWidth < 1080) {
            nSlides = 3;
        }

        if (window.innerWidth < 563) {
            nSlides = 2;
        }

        if (window.innerWidth < 563) {
            nSlides = 2;
        }

        return nSlides;
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        if (this.state.enabled === false) {
            return <div></div>
        }
        var content = this.props.content;
        var savedCulture = localStorage.getItem("siteLanguage");

        if (savedCulture === undefined) {
            savedCulture = "pt-pt";
        }

        return (
            
            <div className="services" >
                <span className="goto" id="services"></span>
                <div className="content">
                    <div className="left">
                        <div className="padding">
                            <h2 className="bold leftTitle">{content.title}</h2>
                            <h2 className="leftTitle">{content.subTitle}</h2>
                        </div>
                        <div className="allServicesArrow padding">
                            <NavHashLink to={content.allservicesLink}><h3 className="leftTitle">{content.allservices}</h3></NavHashLink>
                            <div className="arrowContainer">
                                <span className="arrow" style={{ backgroundImage: `url(${content.allservicesImageUrl})` }}></span>
                            </div>
                        </div>
                    </div>
                    <div className="right" id="carouselProviderContainer" >
                        <CarouselProvider className="servicescarousel" key={savedCulture}
                            naturalSlideWidth={200}
                            naturalSlideHeight={300}
                            visibleSlides={this.state.numberOfSlides}
                            infinite={true}
                            isPlaying={true}
                            totalSlides={content.servicesItems.length}>
                            <Slider >
                                {
                                    this.props.content.servicesItems.map(({ title, id, link, imageUrl }) => (
                                        <Slide key={id}><ServicesItem title={title} link={link} imageUrl={imageUrl} /></Slide>
                                    ))
                                }
                            </Slider>
                        </CarouselProvider>
                    </div>
                </div>
            </div>
        );
    }
}


export default Services;