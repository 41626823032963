import React from 'react'

import './advertsItem.styles.scss'
import { NavHashLink } from 'react-router-hash-link';

const AdvertsItem = ({ id, title, imageUrl, link }) =>
(
    <NavHashLink to={link}>
        <div className={"AdvertsItem AdvertsItem" + id} style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="title">{title}</div>
        </div>
    </NavHashLink>
);

export default AdvertsItem