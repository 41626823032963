import React from 'react'
import  { Redirect } from 'react-router-dom'
import '../../page.styles.scss'
import './newscontentpage.styles.scss'
import parse from 'html-react-parser';
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import Related from '../../components/related/related.component'

import { CarouselProvider, Slider, Slide} from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';

export default class NewsContentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            pathId: props.pathId,
            numberOfSlides: this.getDimension(),
            isLoaded: false,
            error: false,
            currentLanguage: props.language,
            content: { key: "none", value: {} }
        };
    }

    updateDimensions = () => {
        this.setState({ numberOfSlides: this.getDimension() });
    };

    getDimension() {
        var nSlides = 3;

        if (window.innerWidth > 645 && window.innerWidth < 1080) {
            nSlides = 2;
        }

        if (window.innerWidth < 645) {
            nSlides = 1;
        }
        return nSlides;
    }

    componentDidMount() {
        this.UpdateContent();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if(this.props.pathId !== prevProps.pathId || this.props.language !== prevProps.language)
        {
            this.UpdateContent()
        }
    }

    UpdateContent() {
        var serviceKey = this.getServiceKey(this.props.language);
        var language =  this.props.language;
        var newsId = this.props.pathId;
        var content = this.state.content[serviceKey];

        if (content) {
            this.setState( {
                currentLanguage: this.props.language
            });

            return;
        }

        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // request successful
                    var response = xhr.responseText;
                    var json = JSON.parse(response);

                    let copyContent = { ...this.state.content }; //create a new copy

                    copyContent[serviceKey] = json;

                    this.setState({
                        isLoaded: true,
                        error: false,
                        content: copyContent,
                        currentLanguage: language
                    });

                } else {
                    // error
                    this.setState({
                        isLoaded: true,
                        error: true
                    });
                }
            }
        });

        xhr.open("GET", process.env.REACT_APP_APIURL  + "/content/news/" + newsId + "?culture=" + language, true);
        xhr.send();
    }

    getServiceKey(language)
    {
        return this.props.match.params.id + language;
    }

    render() {
        var serviceKey = this.getServiceKey(this.state.currentLanguage);
        var content = this.state.content[serviceKey];
        var body;

        console.log(serviceKey);
        if (!this.state.isLoaded) {
            // yet loading
            body = <div>Loading...</div>
        } else if (this.state.error) {
            // error
            return <Redirect to='/'/>
        } else {
            this.props.crumbs[this.props.crumbs.length - 1].name = content.title;
            console.log("banner:" + content.bannerUrl);
            if(content.bannerUrl && content.bannerUrl.length > 0)
            {
                body = <div className="page" >
                <div className="newscontent" id={this.props.pathId}>
                    <div className="banner" style={{ backgroundImage: `url('${content.bannerUrl}')` }}>
                        <div className="content">
                            <div className="left">
                                <h2>{content.title}</h2>
                            </div>
                        </div>
                    </div>
                    <Breadcrumbs crumbs={this.props.crumbs} />
                    <div className="content">
                        <div>
                            {parse(content.text)}
                        </div>
                        <div>
                            <CarouselProvider className="serviceImages"
                            naturalSlideWidth={100}
                            naturalSlideHeight={200}
                            visibleSlides={this.state.numberOfSlides}
                                infinite={true}
                                isPlaying={true}
                                interval={6000}
                                totalSlides={content.images.length}>
                                <Slider>
                                    {
                                        content.images.map(({ id, imageUrl, alt }) => (
                                            <Slide key={id}><img className="img" src={imageUrl} alt={alt} /></Slide>
                                        ))
                                    }
                                </Slider>
                            </CarouselProvider>
                        </div>
                        <Related title={content.relatedTitle} content={content.related} />
                    </div>
                </div>
            </div>
            }
            else{

            body = <div className="page" >
                <div className="newscontent" id={this.props.pathId}>
                    <Breadcrumbs crumbs={this.props.crumbs} />
                    <div className="content">
                        <div>
                            {parse(content.text)}
                        </div>
                        <div>
                            <CarouselProvider className="serviceImages"
                            naturalSlideWidth={100}
                            naturalSlideHeight={200}
                            visibleSlides={this.state.numberOfSlides}
                                infinite={true}
                                isPlaying={true}
                                interval={6000}
                                totalSlides={content.images.length}>
                                <Slider>
                                    {
                                        content.images.map(({ id, imageUrl, alt }) => (
                                            <Slide key={id}><img className="img" src={imageUrl} alt={alt} /></Slide>
                                        ))
                                    }
                                </Slider>
                            </CarouselProvider>
                        </div>
                        <Related title={content.relatedTitle} content={content.related} />
                    </div>
                </div>
            </div>
            }
        }

        return body;
    }
}