import React from 'react'

import './related.styles.scss'

import ArticleReference from '../articleReference/articleReference.component'

export default function Related(props) {
    if(props.content && props.content.length > 0)
    return (
        <div className="related">
            <div className="relatedTitle">{props.title}</div>
            <div className="relatedContainer">
                {props.content &&  props.content.map(({ id, link, imageUrl, text }) => (
                    <ArticleReference key={id} link={link} imageUrl={imageUrl} teaserText={text} />
                ))
                }
            </div>
        </div>
    );
    else
    return null;
}
