import React from 'react'

import './menuHeader.styles.scss'

import { NavHashLink } from 'react-router-hash-link';

class MenuHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            isListOpen: false
        };

        this.toggleClass = this.toggleClass.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggleClass(e) {
        e.stopPropagation();
        document.body.style.overflow = 'unset';
        this.setState({
            isListOpen: false,
          });
    };
    

    handleChange({target}){
        this.setState({
            isListOpen: target.checked
          });
        if (target.checked){
            document.body.style.overflow = 'unset';
           target.removeAttribute('checked');
          
        } else {
            document.body.style.overflow = 'hidden';
           target.setAttribute('checked', true);
          
        }
    }

    componentDidUpdate(){
        const { isListOpen } = this.state;
      
        setTimeout(() => {
          if(isListOpen){
            window.addEventListener('click', this.toggleClass)
          }
          else{
            window.removeEventListener('click', this.toggleClass)
          }
        }, 0)
      }

    render() {
        return (
            <div className="menuHeader wrap">
                <div className="logo">
                    <NavHashLink to="/#homepage" activeClassName="active" className="noSelect">
                        <img className="image" src={this.props.menu.logo.imageUrl} alt={this.props.menu.logo.title} />
                    </NavHashLink>
                </div>
                <div className="menuItems">
                    <input type="checkbox" id="button"  ref="dropdowncheck" checked={this.state.isListOpen} onChange={this.handleChange}/>
                    <label htmlFor="button" className="noSelect"></label>
                    <div className="menucontainer" v-scroll-stop="false">
                        <ul>
                            {
                                this.props.menu.items.map(({ title, id, link }) => (
                                    <li key={id}><NavHashLink to={link} activeClassName="active" onClick={this.toggleClass}>{title}</NavHashLink></li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuHeader;