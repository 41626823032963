import React from 'react'

import './links.styles.scss'
import { CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            enabled: this.props.content.enabled,
        }
    }

    render() {
        if (this.state.enabled === false)
        {
            return <div></div>
        }
        const content = this.props.content;

        return (
            <div className="links" >
                <span className="goto" id="links"></span>
                <div className="content">
                    <div className="left">
                        <CarouselProvider className="linksCarousel"
                          naturalSlideWidth={200}
                          naturalSlideHeight={300}
                            visibleSlides={1}
                            infinite={true}
                            isPlaying={true}
                            interval={6000}
                            totalSlides={content.images.length}>
                            <Slider>
                                {
                                    content.images.map(({ id, link, name }) => (
                                        <Slide key={id}><img className="image" src={link} alt={name} /></Slide>
                                    ))
                                }
                            </Slider>
                        </CarouselProvider>
                    </div>
                    <div className="right">
                        <h2 className="title">{parse(content.title)}</h2>
                        <div className="text">{parse(content.text)}</div>
                        <div className="linksButtons">
                            {content.links.filter(v => v.link !== null).map(({ id, title, link }) => (
                                <div className="link" key={id}>
                                    <Link to={link}>{title}</Link> 
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Links