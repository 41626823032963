import React from 'react'
import LineHeader from './lineHeader/lineHeader.component'
import MenuHeader from './menuHeader/menuHeader.component'

import './header.styles.scss'

class Header extends React.Component {

    render() {
        return (
            <div className="header">
                <LineHeader menu={this.props.content.linemenu} changeCultureHandler={this.props.changeCultureHandler}  />
                <MenuHeader menu={this.props.content.menu} active={this.props.active} />
            </div>
        );
    }
}
export default Header;