import React from 'react'
import parse from 'html-react-parser';
import './AdvertItem.styles.scss'
import { NavHashLink } from 'react-router-hash-link';


class AdvertItem extends React.Component {
    render() {
        return <InnerAdvertItem imageUrl={this.props.imageUrl} title={this.props.title} text={this.props.text} link={this.props.link} readMore={this.props.readMore}></InnerAdvertItem>
    }
}

function InnerAdvertItem(props) {
        if (props.link) {
            return <div className="advertItem">
            <div className="content">
                <NavHashLink to={props.link} className="readmore">
                    <div className="imageContainer">
                        <img className="image" src={props.imageUrl} alt={props.title} />
                        <div className="advertTitle">
                            <h2 className="title bold">{props.title}</h2>
                        </div>
                    </div>
                </NavHashLink>
                <div className="advertItemText">
                    <div className="advertItemTextContent">{parse(props.text)}</div>
                    <NavHashLink to={props.link} className="readmore">{props.readMore}</NavHashLink>
                </div>
            </div>
        </div>
        }
        else {
           return  <div className="advertItem">
            <div className="content">
                    <div className="imageContainer">
                        <img className="image" src={props.imageUrl} alt={props.title} />
                        <div className="advertTitle">
                            <h2 className="title bold">{props.title}</h2>
                        </div>
                    </div>
              
                <div className="advertItemText">
                    <div className="advertItemTextContent">{parse(props.text)}</div>
                </div>
            </div>
        </div>
        }
}



export default AdvertItem;