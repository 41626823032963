import React from 'react'

import './servicesItem.styles.scss'
import { NavHashLink } from 'react-router-hash-link';


class ServicesItem extends React.Component {
    render() {
        if (this.props.link) {
            return <NavHashLink to={this.props.link}>
               <InnerServicesItem imageUrl={this.props.imageUrl} title={this.props.title}></InnerServicesItem>
            </NavHashLink>
        }
        else {
            return <InnerServicesItem imageUrl={this.props.imageUrl} title={this.props.title}></InnerServicesItem>
        }
    }
}


function InnerServicesItem(props) {
    return (
        <div className="servicesItem">
        <div className="content">
            <img className="image" src={props.imageUrl} alt={props.title} />
            <div className="serviceTitle">
                <h2 className="title bold">{props.title}</h2>
            </div>
        </div>
    </div>
    );
}



export default ServicesItem;