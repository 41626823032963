import React from "react";
import { Link } from "react-router-dom";

import './breadcrumbs.styles.scss'

export class Breadcrumbs extends React.Component {
  render() {
    if (this.props.crumbs.length <= 1) {
      return null;
    }
   
    return (
      <div className="breadcrumbs">
        <div className="content">
          <div className="breadcrumbsLine">
            {/* Link back to any previous steps of the breadcrumb. */}
            {this.props.crumbs.map(({ name, path }, key) => 
              key + 1 ===  this.props.crumbs.length ? (
                <span key={key} className="breadcrumbActive">
                  {name}
                </span>
              ) : (
                <div className="breadcrumbItem" key={key}>
                  <Link className="breadcrumbSegment" to={path}>
                    {name}
                  </Link><span>&nbsp;-&nbsp;</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    ); 
  }
}
